import Prism from 'prismjs'
import 'prismjs/components/prism-java.js'
import 'prismjs/components/prism-javascript.js'

// import './css/prismjs/prism-tomorrow.css' // prism js 공홈에서 download -> public으로 옮김
import {Editor} from '@toast-ui/react-editor' // 코드에 강조색을 부여하기 위해서는 react editor 3.1.1을 설치해야 함
import './css/toastui-editor.css'
import './css/toastui-editor-dark.css'

import './css/toastui-editor-plugin-code-syntax-highlight.css'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import {useEffect, useRef, forwardRef} from 'react'
import { post, post_file, URLs, DB } from "../API/API";

// TOAST UI EDITOR > SYNTAX HIGHLIGHT
// https://velog.io/@y0ungg/React-18-ES6%EC%97%90%EC%84%9C-Toast-UI-Editor-plugins-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0-codeSyntaxHighlight

// prettier-ignore
export const StyledMdEditor = forwardRef((props, ref) => {

    function uploadFile (blob, callback) {

        const formData = new FormData();
        formData.append("file", blob);

        post_file(URLs.SAVE_IMAGE_FILE, formData, (res) => {
            console.log ("SAVE IMAGE RES", res)
            callback(res, 'image alt attribute');
            // post(URLs.APPEND_JSON_CONTENT, {file_name:DB.WORK_IMAGES, content:{src: res, title: imageTitle}}, (res)=> {
            //     console.log (res)
            //     setItemData(res)
                
            // })
        })
    }


    function handleImage (blob, callback) {
        uploadFile(blob, callback)
    }

    


    return (
        <div style={{width:'100%', height:'100%'}}>
            <link rel="stylesheet" type="text/css" href="/css/prism-tomorrow.css" />
            <Editor
                initialValue={props.source}
                previewStyle='vertical'
                width='100%'
                height="100%"
                // initialEditType='wysiwig'
                initialEditType='markdown'
                // hideModeSwitch={true}
                theme={"dark"}
                useCommandShortcut={true}
                useDefaultHTMLSanitizer={true}
                plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
                ref={ref}
                hooks = {{
                    addImageBlobHook: handleImage
                }} 
                autofocus={false}
            />
        </div>
    )
})
