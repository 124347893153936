import {
    Box,
    Button,
    InputBase,
    styled,
    TextField,
    InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { post, URLs, DB } from "./admin/API/API";
import ReactMarkdown from 'react-markdown'

function SearchBar(props) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 3,
                py: 1,
                bgcolor: "#a36410",
            }}
        >
            <Box sx={{ color: "white", fontWeight: 700 }}> 검색 </Box>
            <Box
                sx={{
                    border: "1px solid white",
                    borderRadius: "20px",
                    px: "16px",
                    py: "2px",
                    width: 0.6,
                }}
            >
                <InputBase
                    inputProps={{
                        style: {
                            color: "white",
                        },
                    }}
                    sx={{
                        pt: "2px",
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon sx={{ color: "white" }} />
                        </InputAdornment>
                    }
                    size="small"
                    value={props.word}
                    onChange={(e) => props.setWord(e.target.value)}
                />
            </Box>
        </Box>
    );
}

export function LeftBar(props) {
    const [word, setWord] = useState("");
    console.log ("LEFTBAR", props.stories)
    return (
        <Box
            sx={{
                width: "250px",
                backgroundColor: "#222",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                pt: 1,
            }}
        >
            <SearchBar word={word} setWord={setWord} />
            {props.stories && props.stories
                .filter((story) => story.title.includes(word))
                .map((one, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: "230px",
                            height: "30px",
                            color: "white",
                            fontWeight: 700,
                            backgroundColor: props.selectedStories.find(ss => ss.id === one.id ) ? "#333":"#444",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover":{
                                cursor:'pointer'
                            }
                        }}
                        onClick={() => {
                            const found = props.selectedStories.find(ss => ss.id === one.id )
                            if (found)
                                props.setSelectedStories(props.selectedStories.filter(ss => ss.id !== one.id))
                            else
                                props.setSelectedStories([...props.selectedStories, one])
                        }}
                    >
                        {one.title}
                    </Box>
                ))}
        </Box>
    );
}

export const ScrollDiv = styled("div")`
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
        background: #555;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #888;
    }
`;

const messages = [];
export function Content(props) {
    const [histories, setHistories] = useState([]);
    const [message, setMessage] = useState("");
    const scrollRef = useRef()

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
    }, [histories])

    function pushMessages(role, message) {

        if (message.startsWith('```markdown')){
            message = message.replace("```markdown", "").replace("```", "")
        }

        messages.push({ role: role, content: message });
        setHistories([...messages]);
    }

    function sendChat(message) {
        pushMessages("user", message);
        post(URLs.CHAT, message, (res) => {
            console.log(res);
            pushMessages("system", res);
        });
    }

    function sendQuery(message, references) {
        pushMessages("user", message);
        const query = {
            query:message,
            references:references
        }
        post(URLs.QUERY, query, (res) => {
            console.log(res);
            pushMessages("system", res);
        });
    }

    

    const storyTitles = props.selectedStories.map(one => one.title).join(", ")

    return (
        <Box
            sx={{
                width: "calc(100% - 250px)",
                backgroundColor: "#555",
                position: "relative",
            }}
        >
            <ScrollDiv
                style={{ height: "calc(100vh - 350px)", overflow: "auto" }}
                ref={scrollRef}
            >
                <Box sx={{width:1, display:'flex', pt:1, justifyContent:'center', color:'white'}}> 
                    왼쪽에서 스토리를 선택한 후 소중한 질문 받을께요!
                </Box>
                
                {histories.map((one, index) => (
                    <Box
                        key={index}
                        sx={{
                            color: "white",
                            backgroundColor: one.role === "user" ? "#444" : "transparent",
                            p: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ width: 0.7 }}>
                            <ReactMarkdown 

                      
                            components={{
                                img: ({node, ...props}) => <img style={{maxWidth: '100%'}}{...props} alt=""/> 
                            }}>
                                {one.content}
                            </ReactMarkdown>
                            {/* <div  style={{padding:15}}>
                                <MDEditor.Markdown
                                    style={{ padding: 10 }}
                                    source={one.content}
                                />
                            </div> */}
                            
                            {/* <MarkdownPreview wrapperElement={{'data-color-mode': 'light'}} source={one.content} style={{
                                borderColor: 'grey',
                                // whiteSpace: 'pre-wrap',
                                background: 'transparent',
                            }} /> */}
                        </Box>
                    </Box>
                ))}
            </ScrollDiv>
            <Box
                sx={{
                    width: "calc(100% - 32px)",
                    p: 2,
                    position: "absolute",
                    bottom: 0,
                }}
            >
                <TextField
                    fullWidth
                    size="small"
                    inputProps={{
                        style: { color: "white", backgroundColor: "#444" },
                    }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            console.log(message);
                            // sendChat(message);
                            sendQuery(message, props.selectedStories.map(one => one.id));
                            setMessage("");
                        }
                    }}
                />
            </Box>
        </Box>
    );
}

export default function AI(props) {
    const [text, setText] = useState("");
    const [stories, setStories] = useState([])
    const [selectedStories, setSelectedStories] = useState([])

    useEffect(() => {
        post(URLs.GET_JSON_FILE, DB.STORY, (res) => {
            console.log (res)
            setStories(res)
            if (res.length > 0)
                setSelectedStories([res[0]])
        })
    }, [])

    return (
        <Box
            sx={{
                width: "100%",
                height: "calc(100vh - 282px)",
                display: "flex",
            }}
        >
            <LeftBar stories={stories} selectedStories={selectedStories} setSelectedStories={setSelectedStories} />
            <Content text={text} selectedStories={selectedStories} setText={setText} />
        </Box>
    );
}
