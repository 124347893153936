import { Box, SpeedDial } from "@mui/material";
import React, { useEffect, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import FeedbackIcon from "@mui/icons-material/Feedback";

import Head from "./Head";
import Footer from "./Footer";
import Intro from "./Intro";
import About from "./About";
import Work from "./Work";
import AI from "./AI";
import Contact from "./Contact";
import PlaygroundSpeedDial from "./SpeedDial";
// import APIs from "./API";

import { post, URLs, DB } from "./API/API";


export default function Main(props) {
    const [menu, setMenu] = useState("intro");
    const [data, setData] = useState({})

    const changeData = (pageKey, dataKey, value) => {
        const newData = {...data}
        newData[pageKey][dataKey] = value
        setData(newData)
    }

    useEffect(() => {
        post(URLs.GET_SITE_FILE, DB.SITE, (res) => {
            console.log ("MAIN data from API", res)
            setData(res)
        })
    }, [])
    return (
        <Box sx={{ width: 1, height: 1, boxSizing: "border-box" }}>
            {/* 1 */}
            <Head setMenu={setMenu} />
            {/* 2 */}
            <Box sx={{ mt: "82px", width: 1, boxSizing: "border-box" }}>
                {menu === "intro" ? (
                    <Intro data={data} />
                ) : menu === "about" ? (
                    <About data={data} changeData={changeData} />
                ) : menu === "work" ? (
                    <Work />
                ) : menu === "ai" ? (
                    <AI />
                ) : menu === "contact" ? (
                    <Contact data={data} changeData={changeData}/>
                // ) : menu === "api" ? (
                //     <APIs />
                ) : (
                    <></>
                )}
            </Box>
            <PlaygroundSpeedDial />
            {/* 6 */}
            <Footer data={data} switchAdmin={props.switchAdmin}/>
        </Box>
    );
}
